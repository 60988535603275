.background{
  background-color: #fef7f2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 20px;
}

.placeholder-text{
  font-size: 70px;
  font-weight: 100;
}